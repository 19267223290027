<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0 mr-1">Empresas do grupo econômico:</h4>
        <h3 class="mb-0">{{ economicGroup.nome }}</h3>
      </div>
      <div class="d-flex flex-row align-items-center">
        <span class="font-weight-bold mr-1"> Status </span>
        <b-form-checkbox id="canOperate" v-model="status" switch class="custom-control-success">
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </div>
    </div>
    <div class="">
      <div class="p-1 bg-gray border-gray">
        <validation-observer
          ref="businessGroup"
          #default="{ invalid }"
          class="d-flex flex-row justify-content-between align-items-start"
        >
          <validation-provider
            #default="{ errors }"
            vid="cnpj"
            name="CNPJ"
            :rules="`required|cnpj`"
            class="w-100 mr-2"
          >
            <b-form-input
              id="cnpj"
              v-model="company.cnpj"
              :state="errors.length > 0 ? false : null"
              placeholder="Adicione um novo CNPJ"
              v-mask="'##.###.###/####-##'"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider
            #default="{ errors }"
            vid="name"
            name="Nome"
            :rules="`required`"
            class="w-100"
          >
            <b-form-input
              id="name"
              v-model="company.name"
              :state="errors.length > 0 ? false : null"
              placeholder="Informe um Nome"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-button
            variant="primary"
            :disabled="invalid"
            class="text-nowrap ml-2"
            @click="addCompany"
          >
            Incluir CNPJ
          </b-button>
        </validation-observer>
      </div>
      <div class="cnpjListBody">
        <vue-scrollbar ref="Scrollbar" :style="scrollbar">
          <table class="table-container">
            <tr v-for="(company, index) in assignors" :key="index">
              <td class="cnpj">
                {{ company.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') }}
              </td>
              <td>{{ company.nome }}</td>
              <td class="button">
                <b-button variant="flat-warning" size="sm" @click="deleteCnpj(company.id)">
                  Excluir <feather-icon icon="Trash2Icon" />
                </b-button>
              </td>
            </tr>

            <div
              v-if="!assignors.length"
              class="d-flex justify-content-between align-items-center p-1"
            >
              <span> Nenhuma empresa cadastrada </span>
            </div>
          </table>
        </vue-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BPagination,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, cnpj } from '@validations'
import VueScrollbar from 'vue2-scrollbar'
import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'

export default {
  name: 'assignors',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BPagination,
    BListGroup,
    BButton,
    vSelect,
    BFormCheckbox,
    BInputGroup,
    BFormGroup,
    BListGroupItem,
    BFormInput,
    BInputGroupAppend,
    DatatableFiltersHandlerSidebar,
    ValidationProvider,
    ValidationObserver,
    VueScrollbar,
  },
  data() {
    return {
      company: {
        cnpj: '',
        name: '',
      },
      assignors: [],
      companyHeaders: [
        {
          key: 'cnpj',
          label: 'CNPJ',
        },
        {
          key: 'name',
          label: 'Nome',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      economicGroup: {},
      status: null,
      mounted: false,
      scrollbar: {
        maxHeight: '250px',
        width: '100%',
      },
    }
  },
  async mounted() {
    await this.getEconomicGroupData()
    this.mounted = true
  },
  methods: {
    async deleteCnpj(assignorId) {
      try {
        await this.$store.dispatch('admin/deleteAssignor', assignorId)
        this.$swal({
          title: 'Sucesso!',
          text: 'Empresa excluída com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        await this.getEconomicGroupData()
      } catch (error) {
        console.log(error)
      }
    },
    async addCompany() {
      try {
        await this.$store.dispatch('admin/addAssignor', {
          cedente_grupo_economico_id: this.$route.params.id,
          nome: this.company.name,
          cnpj: this.company.cnpj,
        })
        this.clearCompany()
        this.$swal({
          title: 'Sucesso!',
          text: 'Empresa adicionada com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok',
        })
        await this.getEconomicGroupData()
      } catch (error) {
        console.log(error)
      }
    },
    async getEconomicGroupData() {
      try {
        const { data } = await this.$store.dispatch('admin/getEconomicGroup', this.$route.params.id)
        this.economicGroup = data.data
        this.status = data.data.status === 'ativo' ? true : false
        this.assignors = data.data.cedentes
      } catch (error) {
        console.log(error)
      }
    },
    clearCompany() {
      this.company = {
        cnpj: '',
        name: '',
      }
      this.$refs.businessGroup.reset()
    },
    async block() {
      try {
        await this.$store.dispatch('admin/block', {
          grupo_economico: { ids: [this.$route.params.id] },
        })
      } catch (error) {
        console.log(error)
      }
    },
    async unblock() {
      try {
        await this.$store.dispatch('admin/unblock', {
          grupo_economico: { ids: [this.$route.params.id] },
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  watch: {
    status: {
      handler: function (newValue, oldValue) {
        if (this.mounted) newValue === true ? this.unblock() : this.block()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';

.border-gray {
  border: 1px solid #dae1e8;
}

.bg-gray {
  background-color: #eff4f6;
}

.table-container {
  width: 100%;
  border-collapse: collapse;
}

.table-container td {
  border: 1px solid #dae1e8;
  border-top: none;
  padding: 8px 12px;
  text-align: left;
}

.table-container td.cnpj {
  width: 15%;
  white-space: nowrap;
}

.table-container td.button {
  width: 12%;
  white-space: nowrap;
}
</style>
